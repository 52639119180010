import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./Reducers/Authentication";
import LoginReducer from "./Reducers/Login";
import CommonReducer from "./Reducers/Common";
import WidgetReducer from "./Reducers/Widget";
import FlightReducer from "./Reducers/Flights";
import HotelReducer from "./Reducers/Hotel";

export const store = configureStore({
  reducer: {
    authentication: AuthReducer,
    login: LoginReducer,
    common: CommonReducer,
    widget: WidgetReducer,
    flights: FlightReducer,
    hotels: HotelReducer,
  },
});
