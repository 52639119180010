import React from "react";
import notfound from "../../assets/images/notfound.png";

const NotFound = ({ title }) => {
  return (
    <div className="not_found text-center py-4">
      <img src={notfound} alt="not found" className="img-fluid" />
      <h4 className="text-dark mb-0 mt-3">
        {title ? title : "Data Not Found !"}
      </h4>
    </div>
  );
};

export default NotFound;
